<template>
  <b10-base>
    <ValidationProvider
      v-slot="{ errors }"
      name="descripcion"
      :rules="formRules.descripcion"
    >
      <v-text-field
        v-model="form.descripcion"
        clearable
        flat
        label="Descripción"
        :error-messages="errors"
      />
    </ValidationProvider>
    <ValidationProvider
      v-slot="{ errors }"
      name="fcontacto"
      :rules="formRules.fcontacto"
    >
      <b10-date-time-picker
        v-model="form.fcontacto"
        title="Fecha"
        :error-messages="errors"
      />
    </ValidationProvider>
    <ValidationProvider
      v-slot="{ errors }"
      name="tcontacto"
      :rules="formRules.idtcontacto"
    >
      <b10-autocomplete
        v-model="form.idtcontacto"
        :items="tipoContacto"
        item-value="idtcontacto"
        item-text="descripcion"
        label="Tipo de contacto"
        clearable
        :error-messages="errors"
      />
    </ValidationProvider>
    <ValidationProvider
      v-slot="{ errors }"
      name="canal_comunicacion"
      :rules="formRules.idcanal_comunicacion"
    >
      <b10-autocomplete
        v-model="form.idcanal_comunicacion"
        :items="canalComunicacion"
        item-value="idcanal_comunicacion"
        item-text="descripcion"
        label="Canal de comunicación"
        clearable
        :error-messages="errors"
      />
    </ValidationProvider>
    <b10-textarea
      v-model="form.observaciones"
      label="Observaciones"
    />
  </b10-base>
</template>

<script>
import formMixin from '@/mixins/formMixin'
import Data from './ContactoClienteFormData'

export default {
  mixins: [formMixin],
  data () {
    return {
      form: {
        descripcion: null,
        observaciones: null,
        idtcontacto: null,
        idcanal_comunicacion: null,
        fcontacto: null,
      },
      formRules: {
        descripcion: { required: true },
        idtcontacto: { required: true },
        idcanal_comunicacion: { required: true },
        fcontacto: { required: true },
      },
      tipoContacto: [],
      canalComunicacion: [],
    }
  },
  async created () {
    const resp = await Data.selectLookups(this)
    this.tipoContacto = resp.data.selectTcontacto.result.dataset
    this.canalComunicacion = resp.data.selectCanalComunicacion.result.dataset
    await this.loadForm()
  },
  methods: {
    async loadFormData (id) {
      const formData = await Data.selectFormData(this, id)
      this.form = formData
    },
  },
}
</script>
