var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b10-base',[_c('ValidationProvider',{attrs:{"name":"descripcion","rules":_vm.formRules.descripcion},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"clearable":"","flat":"","label":"Descripción","error-messages":errors},model:{value:(_vm.form.descripcion),callback:function ($$v) {_vm.$set(_vm.form, "descripcion", $$v)},expression:"form.descripcion"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"fcontacto","rules":_vm.formRules.fcontacto},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b10-date-time-picker',{attrs:{"title":"Fecha","error-messages":errors},model:{value:(_vm.form.fcontacto),callback:function ($$v) {_vm.$set(_vm.form, "fcontacto", $$v)},expression:"form.fcontacto"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"tcontacto","rules":_vm.formRules.idtcontacto},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b10-autocomplete',{attrs:{"items":_vm.tipoContacto,"item-value":"idtcontacto","item-text":"descripcion","label":"Tipo de contacto","clearable":"","error-messages":errors},model:{value:(_vm.form.idtcontacto),callback:function ($$v) {_vm.$set(_vm.form, "idtcontacto", $$v)},expression:"form.idtcontacto"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"canal_comunicacion","rules":_vm.formRules.idcanal_comunicacion},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b10-autocomplete',{attrs:{"items":_vm.canalComunicacion,"item-value":"idcanal_comunicacion","item-text":"descripcion","label":"Canal de comunicación","clearable":"","error-messages":errors},model:{value:(_vm.form.idcanal_comunicacion),callback:function ($$v) {_vm.$set(_vm.form, "idcanal_comunicacion", $$v)},expression:"form.idcanal_comunicacion"}})]}}])}),_c('b10-textarea',{attrs:{"label":"Observaciones"},model:{value:(_vm.form.observaciones),callback:function ($$v) {_vm.$set(_vm.form, "observaciones", $$v)},expression:"form.observaciones"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }